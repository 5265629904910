module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-22140317-5","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hacktoberfest Open Hack Day - WebMob Technologies","short_name":"Hacktoberfest Open Hack Day  - WebMob Technologies","description":"Hacktoberfest presented by DigitalOcean and DEV - Organized by WebMob Technologies","start_url":"/","background_color":"#fff","theme_color":"#170f1e","display":"standalone","icon":"static/images/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"889fc2311beb367403b8ba8508bb96e6"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
